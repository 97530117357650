.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.team-main {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

@media (max-width: 600px) {
  .team-main {
    padding-bottom: 0;
    padding-top: 2rem;
  }
}

.team-main-item {
  max-width: 500px;
}

@media (max-width: 992px) {
  .team-main-item {
    text-align: center;
    margin: 0 auto;
  }
}

.team-main__title {
  font-size: 18rem;
  font-weight: 700;
  color: #326A9F;
  margin: 0;
  line-height: 1;
  padding-bottom: 0;
}

@media (max-width: 560px) {
  .team-main__title {
    font-size: 14.5rem;
  }
}

.team-main__subtitle {
  font-size: 3.2rem;
  font-weight: 600;
  color: #333333;
  line-height: 1.5;
  padding-top: 0;
  margin-top: 1.9rem;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .team-main__subtitle {
    font-size: 2.8rem;
    margin-bottom: 0.5em;
  }
}

@media (max-width: 560px) {
  .team-main__subtitle {
    font-size: 2.4rem;
  }
}

.team-main__text {
  font-size: 2.2rem;
  color: #333333;
  margin-top: 0;
  line-height: 1.5;
}

@media (max-width: 560px) {
  .team-main__text {
    font-size: 1.6rem;
  }
}

.team-main__img {
  position: absolute;
  right: -9rem;
  top: 3.5rem;
  z-index: -1;
}

@media (max-width: 992px) {
  .team-main__img {
    position: initial;
    right: initial;
    bottom: initial;
    width: 100%;
    margin: 3rem auto 0;
    display: block;
  }
}

.team-main__link {
  display: inline-block;
  color: #326A9F;
  border-bottom: 1px solid #326A9F;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 3rem;
  transition: .1s ease-in border-bottom;

  &:hover {
    border-bottom: 1px solid transparent;
  }
}

@media (max-width: 560px) {
  .team-btn {
    display: block;
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 560px) {
  .team-main__link {
    display: inline;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
}
